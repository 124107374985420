import React, { useState } from 'react';
import ProjectHeader from './ProjectHeader';
import { Grid, Stack } from '@mui/material';

import { useGetProjectsQuery } from 'src/store/projects';
import Loader from 'src/components/Loader';
import PojectCard from './PojectCard';
import ProjectsSearch from '../Gallry/ProjectsSearch';
import DeleteProject from './DeleteProject';

const ProjectsHome = () => {
  const { data: projects, isLoading } = useGetProjectsQuery();
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);
  const [deletedItem, setDeletedItem] = useState(null);
  const filteredProjects = projects?.filter((project) =>
    project.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearch = (event) => {
    setSearchTerm(event);
  };

  return (
    <>
      <ProjectHeader title={'Projects'} link={'/projects/add'} buttonText={'New Item'} iconClass={'eva:plus-fill'} />
      <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
        <ProjectsSearch projects={projects} value={searchTerm} onChange={handleSearch} />
      </Stack>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {filteredProjects && filteredProjects.length > 0 ? (
            <Grid container spacing={3}>
              {filteredProjects?.map((project) => (
                <PojectCard setDeletedItem={setDeletedItem} setOpen={setOpen} key={project.id} project={project} />
              ))}
            </Grid>
          ) : (
            <p>No projects found.</p>
          )}
        </>
      )}
      {open && <DeleteProject open={open} setOpen={setOpen} item={deletedItem} setDeletedItem={setDeletedItem} />}
    </>
  );
};

export default ProjectsHome;
