import React from 'react';
import ProjectHeader from './ProjectHeader';
import ProjectForm from './ProjectForm';
import { useNavigate } from 'react-router-dom';
import { useAddProjectMutation } from 'src/store/projects';
import toast from 'react-hot-toast';

const AddProject = () => {
  const navigate = useNavigate();
  const [addProject, { isLoading }] = useAddProjectMutation();
  const handleAddProject = async (formData) => {
    try {
      const form = new FormData();
      Object.keys(formData).forEach((key) => {
        form.append(key, formData[key]);
      });

      const response = await addProject(form)
        .unwrap()
        .then((res) => {
          toast.success('Successfully Added!');
          navigate('/projects', { replace: true });
        });
    } catch (error) {
      // Handle error here
      if (error.data) {
        Object.keys(error.data).forEach((key) => {
          toast.error(`${key}: ${error.data[key].join(', ')}`);
        });
      } else {
        toast.error("This didn't work!");
      }
      console.error('Error submitting form:', error);
    }
  };
  return (
    <>
      <ProjectHeader
        title={'Add Project'}
        link={'/projects'}
        buttonText={'Back'}
        iconClass={'eva:arrow-ios-back-fill'}
      />
      <ProjectForm isLoading={isLoading} formTitle={'Add Project'} formID={'add-project'} onSubmit={handleAddProject} />
    </>
  );
};

export default AddProject;
