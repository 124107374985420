import React from 'react';
import ReactDOM from 'react-dom';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { useDeleteGalleryItemMutation } from 'src/store/gallery';
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';

const DeleteGalleryItem = ({ item, open, setOpen, setDeletedItem }) => {
  const [deleteGalleryItem, { isLoading }] = useDeleteGalleryItemMutation();
  const onClose = () => {
    setOpen(false);
    setDeletedItem(null);
  };
  const handleDelete = async () => {
    try {
      await deleteGalleryItem(item.id)
        .unwrap()
        .then(() => {
          toast.success('Successfully Deleted!');
          onClose();
        });
    } catch (error) {
      toast.error("This didn't work!");
      console.error('Error deleting gallery item:', error);
    }
  };

  return ReactDOM.createPortal(
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Gallery Item</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete "{item.title}"?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          onClick={handleDelete}
          loading={isLoading}
          loadingIndicator="Deleting..."
          variant="contained"
          color="error"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>,
    document.body
  );
};

export default DeleteGalleryItem;
