import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Projects from './pages/Projects';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Gallery from './pages/Gallery';
import DashboardApp from './pages/DashboardApp';
import AddGallery from './sections/Gallry/AddGallery';
import GalleryHome from './sections/Gallry/GalleryHome';
import EditGallery from './sections/Gallry/EditGallery';
import ProjectsHome from './sections/projects/ProjectsHome';
import AddProject from './sections/projects/AddProject';
import EditProject from './sections/projects/EditProject';
import ProtectedRoute, { ProtectedLoginRoute } from './layouts/ProtectedRoute';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        { index: true, element: <DashboardApp /> },
        {
          path: 'gallery',
          element: <Gallery />,
          children: [
            { index: true, element: <GalleryHome /> },
            { path: 'add', element: <AddGallery /> },
            { path: ':id', element: <EditGallery /> },
          ],
        },
        {
          path: 'projects',
          element: <Projects />,
          children: [
            { index: true, element: <ProjectsHome /> },
            { path: 'add', element: <AddProject /> },
            { path: ':id', element: <EditProject /> },
          ],
        },
      ],
    },

    // Add this component at the top of your file

    {
      path: 'login',
      element: (
        <ProtectedLoginRoute>
          <Login />
        </ProtectedLoginRoute>
      ),
    },

    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
