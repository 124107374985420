import React from 'react';
import ProjectHeader from './ProjectHeader';
import ProjectForm from './ProjectForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetProjectQuery, useUpdateProjectMutation } from 'src/store/projects';
import toast from 'react-hot-toast';
import Loader from 'src/components/Loader';

const EditProject = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useGetProjectQuery(id);
  console.log(data);
  const [editProject, { isLoading: isUpdating }] = useUpdateProjectMutation();
  const handleEditProject = async (formData) => {
    const form = new FormData();
    Object.keys(formData).forEach((key) => {
      form.append(key, formData[key]);
    });
    try {
      await editProject({ form: form, id: formData?.id })
        .unwrap()
        .then((res) => {
          toast.success('Successfully Updated!');
          navigate('/projects', { replace: true });
        });
    } catch (error) {
      if (error.data) {
        Object.keys(error.data).forEach((key) => {
          toast.error(`${key}: ${error.data[key].join(', ')}`);
        });
      } else {
        toast.error("This didn't work!");
      }
      console.error('Error submitting form:', error);
    }
  };
  return (
    <>
      <ProjectHeader
        title={'Edit Project'}
        link={'/projects'}
        buttonText={'Back'}
        iconClass={'eva:arrow-ios-back-fill'}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <ProjectForm
          initialData={data}
          isLoading={isUpdating}
          formTitle={'Edit Project'}
          formID={'edit-project'}
          onSubmit={handleEditProject}
        />
      )}
    </>
  );
};

export default EditProject;
