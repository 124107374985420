import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Box, Card, Link, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils

// components
import Label from '../../components/Label';
import Iconify from 'src/components/Iconify';
import { WEBSITE_URL } from 'src/utils/constant';

// ----------------------------------------------------------------------

const GalleryItemImage = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

GalleryItem.propTypes = {
  item: PropTypes.object,
};

export default function GalleryItem({ item, setDeletedItem, setOpen }) {
  const navigate = useNavigate();
  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <Label
          variant="filled"
          color={'info'}
          sx={{
            zIndex: 9,
            top: 10,
            right: 10,
            position: 'absolute',
            textTransform: 'uppercase',
            py: 3,
            px: 1,
            cursor: 'pointer',
          }}
          onClick={() => navigate(`/gallery/${item?.id}`)}
        >
          <Iconify icon={'eva:edit-outline'} sx={{ width: 24, height: 24 }} />
        </Label>
        <Label
          variant="filled"
          color={'error'}
          sx={{
            zIndex: 9,
            top: 10,
            right: 60,
            position: 'absolute',
            textTransform: 'uppercase',
            py: 3,
            px: 1,
            cursor: 'pointer',
          }}
          onClick={() => {
            setDeletedItem(item);
            setOpen(true);
          }}
        >
          <Iconify icon={'eva:trash-2-fill'} sx={{ width: 24, height: 24 }} />
        </Label>

        <GalleryItemImage alt={item?.title} src={WEBSITE_URL + item?.image} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link to={`/gallery/${item?.id}`} color="inherit" underline="hover" component={RouterLink}>
          <Typography variant="subtitle2" noWrap>
            {item?.title}
          </Typography>
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'text.disabled',
              }}
            >
              {item?.description}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
