import { Box } from '@mui/material';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Iconify from 'src/components/Iconify';
import GalleryHeader from './GalleryHeader';

import { useAddGalleryItemMutation } from 'src/store/gallery';
import GalleryForm from './GalleryForm';
import toast from 'react-hot-toast';

const AddGallery = () => {
  const [triggerCreateData, { isLoading }] = useAddGalleryItemMutation();
  const navigate = useNavigate();
  const handleSubmit = async (formData) => {
    try {
      const form = new FormData();
      Object.keys(formData).forEach((key) => {
        form.append(key, formData[key]);
      });

      const response = await triggerCreateData(form)
        .unwrap()
        .then((res) => {
          toast.success('Successfully Added!');
          navigate('/gallery', { replace: true });
        });
    } catch (error) {
      // Handle error here
      if (error.data) {
        Object.keys(error.data).forEach((key) => {
          toast.error(`${key}: ${error.data[key].join(', ')}`);
        });
      } else {
        toast.error("This didn't work!");
      }
      console.error('Error submitting form:', error);
    }
  };
  return (
    <>
      <GalleryHeader
        title={'Add Gallery'}
        to={'/gallery'}
        buttonText={'go Back'}
        iconClass={'eva:arrow-ios-back-outline'}
      />
      <GalleryForm formID={'add-form'} onSubmit={handleSubmit} formTitle="Add New Gallery" isLoading={isLoading} />
    </>
  );
};

export default AddGallery;
