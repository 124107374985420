// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [

  {
    title: 'gallery',
    path: '/gallery',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'Projects',
    path: '/projects',
    icon: getIcon('eva:file-text-fill'),
  },
 
];

export default navConfig;
