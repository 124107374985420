import React from 'react';
import GalleryHeader from './GalleryHeader';
import GalleryForm from './GalleryForm';
import { useGetGalleryItemQuery, useUpdateGalleryItemMutation } from 'src/store/gallery';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'src/components/Loader';
import toast from 'react-hot-toast';

const EditGallery = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, isLoading } = useGetGalleryItemQuery(id);
  //   console.log(data);
  const [triggerUpdateData, { isLoading: updateLoading }] = useUpdateGalleryItemMutation();
  const handleSubmit = async (formData) => {
    const form = new FormData();
    Object.keys(formData).forEach((key) => {
      form.append(key, formData[key]);
    });
    try {
      await triggerUpdateData({ form: form, id: formData?.id })
        .unwrap()
        .then((res) => {
          toast.success('Successfully Updated!');
          navigate('/gallery', { replace: true });
        });
    } catch (error) {
      if (error.data) {
        Object.keys(error.data).forEach((key) => {
          toast.error(`${key}: ${error.data[key].join(', ')}`);
        });
      } else {
        toast.error("This didn't work!");
      }
      console.error('Error submitting form:', error);
    }
  };
  return (
    <>
      <GalleryHeader
        title={'Edit Gallery'}
        to={'/gallery'}
        buttonText={'go Back'}
        iconClass={'eva:arrow-ios-back-outline'}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <GalleryForm
          formID={'edit-form'}
          isLoading={updateLoading}
          initialData={data}
          onSubmit={handleSubmit}
          formTitle="Edit Gallery"
        />
      )}
    </>
  );
};

export default EditGallery;
