import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Box, TextField, Button, Typography, Container, Paper, Input } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from 'src/components/Iconify';
import { WEBSITE_URL } from 'src/utils/constant';

const AddProjectSchema = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .min(1, 'Title must be at least 1 character')
    .max(255, 'Title must not exceed 255 characters'),
  description: Yup.string().required('Description is required').min(1, 'Description must be at least 1 character'),
  image: Yup.mixed()
    .required('Image is required')
    .test('fileType', 'Unsupported file format', (value) => {
      if (value) {
        return ['image/jpeg', 'image/png', 'image/gif'].includes(value.type);
      }
      return false;
    }),
  date: Yup.date().required('Date is required'),
  service: Yup.string().required('Service is required'),
  location: Yup.string().max(255, 'Location must not exceed 255 characters').nullable(),
  client: Yup.string().max(255, 'Client must not exceed 255 characters').nullable(),
});

const ProjectForm = ({ initialData, onSubmit, formTitle, isLoading, formID }) => {
  const [formData, setFormData] = useState(
    initialData || {
      title: '',
      description: '',
      image: '',
      date: null,
      service: '',
      location: '',
      client: '',
    }
  );
  const [errors, setErrors] = useState({});

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({ ...prevData, image: file }));
    validateField('image', file);
  };
  const validateField = async (name, value) => {
    try {
      await AddProjectSchema.validateAt(name, { [name]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
    } catch (error) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    validateField(name, value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await AddProjectSchema.validate(formData, { abortEarly: false });

      onSubmit(formData);
    } catch (validationErrors) {
      const newErrors = {};
      if (validationErrors.inner) {
        validationErrors.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
      }
      setErrors(newErrors);
    }
  };

  const urlToFile = async (imageUrl, fileName) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      return new File([blob], fileName, { type: blob.type });
    } catch (error) {
      console.error('Error converting URL to File:', error);
      return null;
    }
  };

  useEffect(() => {
    if (initialData) {
      Object.keys(initialData).forEach(async (key) => {
        if (key === 'image' && initialData.image && typeof initialData.image === 'string') {
          console.log(initialData.image.split('/').pop());
          const file = await urlToFile(WEBSITE_URL + initialData.image, initialData.image.split('/').pop());
          if (file) {
            setFormData((prevData) => ({ ...prevData, image: file }));
            validateField(key, file);
          }
        } else {
          validateField(key, initialData[key]);
        }
      });
    }
  }, [initialData]);

  const imagePreviewSrc = formData.image
    ? typeof formData.image === 'string'
      ? WEBSITE_URL + formData.image
      : URL.createObjectURL(formData.image)
    : null;

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          {formTitle}
        </Typography>
        <Box component="form" id={formID} onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Input
            type="file"
            onChange={handleImageChange}
            accept="image/*"
            fullWidth
            sx={{ mb: 2 }}
            inputProps={{ style: { display: 'none' } }}
            id="image-upload"
          />
          <label htmlFor="image-upload">
            <Button
              variant="outlined"
              component="span"
              fullWidth
              startIcon={<Iconify icon="ic:round-add-a-photo" />}
              sx={{
                border: '2px dashed #ccc',
                borderRadius: '4px',
                mb: 2,
                padding: '16px',
                textAlign: 'center',
                '&:hover': {
                  border: '2px dashed #1976d2',
                },
              }}
            >
              <Typography variant="body1">Click to upload</Typography>
            </Button>
          </label>
          {imagePreviewSrc && (
            <Box sx={{ mt: 2, mb: 2 }}>
              <img src={imagePreviewSrc} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
            </Box>
          )}
          {errors.image && (
            <Typography color="error" variant="body2" sx={{ mb: 2 }}>
              {errors.image}
            </Typography>
          )}
          <TextField
            fullWidth
            name="title"
            label="Project Title"
            value={formData.title}
            onChange={handleChange}
            error={!!errors.title}
            helperText={errors.title}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            name="description"
            label="Project Description"
            value={formData.description}
            onChange={handleChange}
            error={!!errors.description}
            helperText={errors.description}
            multiline
            rows={4}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            name="date"
            label="Project Date"
            type="date"
            value={formData.date}
            onChange={handleChange}
            error={!!errors.date}
            helperText={errors.date}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            name="service"
            label="Service"
            value={formData.service}
            onChange={handleChange}
            error={!!errors.service}
            helperText={errors.service}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            name="location"
            label="Location"
            value={formData.location}
            onChange={handleChange}
            error={!!errors.location}
            helperText={errors.location}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            name="client"
            label="Client"
            value={formData.client}
            onChange={handleChange}
            error={!!errors.client}
            helperText={errors.client}
            sx={{ mb: 2 }}
          />
          <LoadingButton
            loading={isLoading}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            form={formID}
          >
            {initialData ? 'Update Project' : 'Create Project'}
          </LoadingButton>
        </Box>
      </Paper>
    </Container>
  );
};

export default ProjectForm;
