import React from 'react';

import GalleryHeader from './GalleryHeader';
import GalleryList from './GalleryList';

const GalleryHome = () => {
  return (
    <>
      <GalleryHeader title={'Gallery'} to={'/gallery/add'} buttonText={'New Item'} iconClass={'eva:plus-fill'} />

      <GalleryList />
    </>
  );
};

export default GalleryHome;
