import { Container } from '@mui/material';
import Page from '../components/Page';

import { Outlet } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function EcommerceShop() {
  return (
    <Page title="ABRAJ - Gallery">
      <Container>
        <Outlet />
      </Container>
    </Page>
  );
}
