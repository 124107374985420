import { Box } from '@mui/material';
import React from 'react';

const Loader = () => {
  return (
    <>
      <div className="loader-wrapper">
        <div className="loader-container">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
        <Box
          component="img"
          src="/static/logo.png"
          sx={{
            width: 60,
            height: 60,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </div>
    </>
  );
};

export default Loader;
