import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from '../../utils/constant';

export const galleryApi = createApi({
  reducerPath: 'galleryApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/galleries`,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Token ${localStorage.getItem('token')}`);
      return headers;
    },
  }),
  tagTypes: ['Gallery'],
  endpoints: (builder) => ({
    getGalleryItems: builder.query({
      query: () => {
        return {
          url: '/',
          method: 'GET',
        };
      },
      providesTags: ['Gallery'],
    }),
    getGalleryItem: builder.query({
      query: (id) => `detail/${id}`,
      providesTags: ['Gallery'],
    }),
    addGalleryItem: builder.mutation({
      query: (newItem) => {
        return {
          url: '/create/',
          method: 'POST',
          body: newItem,
        };
      },
      invalidatesTags: ['Gallery'],
    }),
    updateGalleryItem: builder.mutation({
      query: ({ form, id }) => ({
        url: `update/${id}/`,
        method: 'PUT',
        body: form,
      }),
      invalidatesTags: ['Gallery'],
    }),
    deleteGalleryItem: builder.mutation({
      query: (id) => ({
        url: `delete/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Gallery'],
    }),
  }),
});

export const {
  useGetGalleryItemsQuery,
  useGetGalleryItemQuery,
  useAddGalleryItemMutation,
  useUpdateGalleryItemMutation,
  useDeleteGalleryItemMutation,
} = galleryApi;
