// routes
import { Provider } from 'react-redux';
import { store } from './store';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import { Toaster } from 'react-hot-toast';
import './index.css';
// ----------------------------------------------------------------------

export default function App() {
  return (
    <Provider store={store}>
      <Toaster />
      <ThemeProvider>
        <ScrollToTop />
        <BaseOptionChartStyle />

        <Router />
      </ThemeProvider>
    </Provider>
  );
}
