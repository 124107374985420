import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { galleryApi } from './gallery';
import { projectsApi } from './projects';
import userReducer from './login';

export const store = configureStore({
  reducer: {
    [galleryApi.reducerPath]: galleryApi.reducer,
    [projectsApi.reducerPath]: projectsApi.reducer,
    // other reducers

    user: userReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(galleryApi.middleware, projectsApi.middleware),
});

setupListeners(store.dispatch);
