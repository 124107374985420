import { Navigate, useLocation } from 'react-router-dom';

function isTokenExpired() {
  const token = localStorage.getItem('token');
  if (!token) {
    return true;
  }
  return false;
}

const ProtectedRoute = ({ children }) => {
  const { pathname } = useLocation();
  console.log(pathname);
  const isAuthenticated = !isTokenExpired();
  console.log('Is Authenticated:', isAuthenticated);

  return isAuthenticated ? children : <Navigate to="/login" />;
};

export const ProtectedLoginRoute = ({ children }) => {
  const { pathname } = useLocation();
  console.log(pathname);
  const isAuthenticated = !isTokenExpired();
  console.log('Is Authenticated:', isAuthenticated);

  if (isAuthenticated && pathname === '/login') {
    return <Navigate to="/" />;
  }
  return children;
};
export default ProtectedRoute;
