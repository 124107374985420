import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import Iconify from 'src/components/Iconify';

const GalleryHeader = ({ title, to, buttonText, iconClass }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          mb: 5,
        }}
      >
        <Typography variant="h4">{title}</Typography>
        <Button variant="contained" component={Link} to={to} startIcon={<Iconify icon={iconClass} />}>
          {buttonText}
        </Button>
      </Box>
    </>
  );
};

export default GalleryHeader;
