import { Outlet } from 'react-router-dom';
// material
import { Container } from '@mui/material';
// components
import Page from '../components/Page';

import { useGetProjectsQuery } from 'src/store/projects';

const Projects = () => {
  const { data: projects, isLoading, isError } = useGetProjectsQuery();

  if (isError) {
    return <div>Error loading projects</div>;
  }

  return (
    <Page title="Projects">
      <Container>
        <Outlet />
      </Container>
    </Page>
  );
};

export default Projects;

// export default function Projects() {
//   return (
//     <Page title="Dashboard: Projects">
//       <Container>
//         <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
//           <Typography variant="h4" gutterBottom>
//             Blog
//           </Typography>
//           <Button variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
//             New Post
//           </Button>
//         </Stack>

//         <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
//           <BlogPostsSearch posts={POSTS} />
//           <BlogPostsSort options={SORT_OPTIONS} />
//         </Stack>

//         <Grid container spacing={3}>
//           {POSTS.map((post, index) => (
//             <BlogPostCard key={post.id} post={post} index={index} />
//           ))}
//         </Grid>
//       </Container>
//     </Page>
//   );
// }
