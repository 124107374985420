import { useState } from 'react';
// material
import { Grid, Input, InputAdornment, Typography } from '@mui/material';
import { useGetGalleryItemsQuery } from 'src/store/gallery';
import GalleryItem from './GalleryItem';
import Iconify from '../../components/Iconify';
import Loader from 'src/components/Loader';
import DeleteGalleryItem from './DeleteGalleryItem';

// component

// ----------------------------------------------------------------------

export default function GalleryList() {
  const { data, isFetching, isError } = useGetGalleryItemsQuery();
  const [deletedItem, setDeletedItem] = useState(null);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = data?.filter((item) => item.title.toLowerCase().includes(searchTerm.toLowerCase()));
  if (isError) return <Typography variant="h4">Error fecthing Gallery</Typography>;
  return (
    <>
      <Input
        autoFocus
        fullWidth
        // disableUnderline
        placeholder="Search…"
        value={searchTerm}
        onChange={handleSearchChange}
        startAdornment={
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </InputAdornment>
        }
        sx={{ mb: 5, py: 1, fontWeight: 'fontWeightBold' }}
      />
      {isFetching ? (
        <Loader />
      ) : (
        <>
          {filteredData && filteredData.length > 0 ? (
            <Grid container spacing={3}>
              {filteredData.map((item) => (
                <Grid key={item?.id} item xs={12} sm={6} md={3}>
                  <GalleryItem item={item} setDeletedItem={setDeletedItem} setOpen={setOpen} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <p>No items found matching your search.</p>
          )}
        </>
      )}

      {open && <DeleteGalleryItem item={deletedItem} setDeletedItem={setDeletedItem} open={open} setOpen={setOpen} />}
    </>
  );
}
