export function fShortenNumber(number) {
  const units = ['', 'K', 'M', 'B', 'T'];
  let unitIndex = 0;
  let scaledNumber = number;

  while (scaledNumber >= 1000 && unitIndex < units.length - 1) {
    unitIndex += 1;
    scaledNumber /= 1000;
  }

  return scaledNumber.toFixed(1).replace('.0', '') + units[unitIndex];
}
